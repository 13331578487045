import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from '../../store/session';

const LoginForm = () => {
	const [errors, setErrors] = useState([]);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const user = useSelector((state) => state.session.user);
	const dispatch = useDispatch();

	const onLogin = async (e) => {
		e.preventDefault();
		const data = await dispatch(login(email, password));
		if (data) {
			setErrors(data);
		}
	};

	const updateEmail = (e) => {
		setEmail(e.target.value);
	};

	const updatePassword = (e) => {
		setPassword(e.target.value);
	};

	if (user) {
		return <Redirect to='/' />;
	}

	return (
		<div className='signup_container'>
			<div className='signup_header'>
				<h1>Login</h1>
				<form onSubmit={onLogin}>
					<div>
						{errors.map((error, ind) => (
							<div className='errors' key={ind}>
								{error}
							</div>
						))}
					</div>
					<div id='form_detail'>
						<label htmlFor='email'>Email</label>
						<input
							name='email'
							type='text'
							placeholder='Email'
							value={email}
							required={true}
							onChange={updateEmail}
						/>
					</div>
					<div id='form_detail'>
						<label htmlFor='password'>Password</label>
						<input
							name='password'
							type='password'
							placeholder='Password'
							value={password}
							required={true}
							onChange={updatePassword}
						/>
						<button type='submit'>Login</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginForm;
