import { fullReset, FULL_RESET } from './full-reset';
import { getAllPins } from './pin';

// constants
const SET_USER = 'session/SET_USER';
const REMOVE_USER = 'session/REMOVE_USER';

export const setUser = (user) => ({
	type: SET_USER,
	payload: user,
});

const removeUser = () => ({
	type: REMOVE_USER,
});

const initialState = { user: null };

export const authenticate = () => async (dispatch) => {
	const response = await fetch('/api/auth/', {
		headers: {
			'Content-Type': 'application/json',
		},
	});
	if (response.ok) {
		const data = await response.json();
		if (data.errors) {
			return;
		}

		dispatch(setUser(data));
	}
};

export const login = (email, password) => async (dispatch) => {
	const response = await fetch('/api/auth/login', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			email,
			password,
		}),
	});

	if (response.ok) {
		const data = await response.json();
		dispatch(setUser(data));
		dispatch(getAllPins());
		return null;
	} else if (response.status < 500) {
		const data = await response.json();
		if (data.errors) {
			return data.errors;
		}
	} else {
		return ['An error occurred. Please try again.'];
	}
};

export const logout = () => async (dispatch) => {
	const response = await fetch('/api/auth/logout', {
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if (response.ok) {
		dispatch(removeUser());
		dispatch(fullReset());
	}
};

export const update_profile = (id, data) => async (dispatch) => {
	const response = await fetch(`/api/users/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});

	if (response.ok) {
		const profile = await response.json();
		dispatch(setUser(profile));
		return profile;
	} else {
		const error = await response.json();
		return error;
	}
};

export const signUp =
	(firstName, lastName, username, email, password) => async (dispatch) => {
		const response = await fetch('/api/auth/signup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				username,
				email,
				password,
				firstName,
				lastName,
			}),
		});

		if (response.ok) {
			const data = await response.json();
			dispatch(setUser(data));
			return null;
		} else if (response.status < 500) {
			const data = await response.json();
			if (data.errors) {
				return data.errors;
			}
		} else {
			return ['An error occurred. Please try again.'];
		}
	};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FULL_RESET:
			return { ...initialState };

		case SET_USER:
			return { user: action.payload };

		case REMOVE_USER:
			return { ...initialState };

		default:
			return state;
	}
}
